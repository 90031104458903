import React from "react";

import useWindowDimensions from "../../useWindowDimensions";

import Banner from "../elements/Banner";

import lips1 from "../../images/lips/lip1.jpg";
import lips2 from "../../images/lips/lip2.jpg";
import lips3 from "../../images/lips/lip3.jpg";
import lips4 from "../../images/lips/lip4.jpg";
import lips5 from "../../images/lips/lip5.jpg";
import BookingButton from "../elements/BookingButton";
import PageTitle from "../elements/PageTitle";

export default function LipInjection() {
  const { width } = useWindowDimensions();
  return (
    <React.Fragment>
      <PageTitle
        title="Lippenunterspritzung"
        description="
                 Schön geformte und volle Lippen gelten als ein wichtiges Symbol
                für Schönheit. Viele Menschen wünschen sich deswegen eine
                Korrektur ihrer Lippen und trauen sich inzwischen, dieses
                Anliegen auch anzugehen."
        canonical="lippenunterspritzung"
      />
      <div className="page-content bruxismus-page bg-white">
        <section className="section-area section-sp1">
          <Banner title="Lippenunterspritzung">
            {width < 520 ? (
              <>
                Lippen-
                <br />
                unterspritzung
              </>
            ) : (
              "Lippenunterspritzung"
            )}
          </Banner>
          <div className="container">
            <h3 className="text-center mt-40">
              Vollere Lippen dank Hyaluronsäure
            </h3>
            <div className="row justify-content-center align-items-centers mt-40">
              <div className="col justify-content-center col-md-6">
                <p>
                  Schön geformte und volle Lippen gelten als ein wichtiges
                  Symbol für Schönheit. Viele Menschen wünschen sich deswegen
                  eine Korrektur ihrer Lippen und trauen sich inzwischen, dieses
                  Anliegen auch anzugehen.
                </p>
                <p>
                  Die Gesichtsästhetik wird durch Zähne und Lippen maßgeblich
                  beeinflusst. Nicht nur gerade und weiße Zähne führen zu einem
                  schönen Anblick, sondern zugleich eine harmonische Lippenform.
                </p>
                <p>
                  Als Zahnarztpraxis haben wir uns auf die Gesundheit und
                  Ästhetik des Mundbereichs spezialisiert und sind Ihr idealer
                  Ansprechpartner für das Aufspritzen Ihrer Lippen.
                </p>
                <p>
                  Vertrauen Sie uns als Ihr persönlicher und medizinischer
                  Ansprechpartner und vergessen Sie die Anonymität und unklaren
                  ästhetischen Ergebnisse von Ketten-Praxen oder die
                  unzureichende medizinische Ausbildung von Heilpraktikern.
                </p>
                <p>
                  Herr d-r Milan Keyhani, M. Sc. ist bekannt für seine besonders
                  natürliche Arbeitsweise. Es ist ihm ausgesprochen wichtig,
                  dass das Ergebnis der Lippenunterspritzung nicht künstlich,
                  sondern absolut harmonisch aussieht.
                </p>
                <p>
                  Lassen Sie sich überzeugen und buchen Sie jetzt Ihren
                  kostenfreien Beratungstermin!
                </p>
                <BookingButton
                  classes="btn-primary mt-20 mb-20"
                  text="Terminbuchung"
                />
              </div>
              <div className="col-md-6">
                <img className="lips__main-image" src={lips1} alt="lips1" />
                <img
                  className="lips__main-image mt-20"
                  src={lips2}
                  alt="lips1"
                />
                {width < 992 && (
                  <div>
                    <img
                      className="lips__main-image mt-20"
                      src={lips3}
                      alt="lips3"
                    />
                    <img
                      className="lips__main-image mt-20"
                      src={lips5}
                      alt="lips2"
                    />
                    <img
                      className="lips__main-image mt-20"
                      src={lips4}
                      alt="lips4"
                    />
                  </div>
                )}
              </div>
            </div>
            {width >= 992 && (
              <div className="lips__images-container row justify-content-center mt-30">
                <div className="col-md-4">
                  <img className="lips__image" src={lips3} alt="lips3" />
                </div>
                <div className="col-md-4">
                  <img className="lips__image" src={lips5} alt="lips2" />
                </div>
                <div className="col-md-4">
                  <img className="lips__image" src={lips4} alt="lips4" />
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}
