import React from "react";
import { useNavigate } from "react-router-dom";

import behandlungsdauer from "../../images/treatment/behandlungsdauer.jpeg";
import eigenanteil from "../../images/treatment/eigenanteil.jpeg";
import kassenleistungen from "../../images/treatment/kassenleistungen.jpeg";
import ausenspange from "../../images/treatment/ausenspange.jpeg";
import bogen from "../../images/treatment/bogen.jpeg";
import brackets from "../../images/treatment/brackets.jpeg";
import diagnostik from "../../images/treatment/diagnostik.jpeg";
import workflow from "../../images/treatment/workflow.jpeg";
import retainer from "../../images/treatment/retainer.jpeg";
// import bronze from "../../images/treatment/bronze.jpeg";
import bronze from "../../images/bronze.avif";
import silver from "../../images/treatment/silver.jpeg";
import gold from "../../images/treatment/gold.jpeg";
import welcome from "../../images/treatment/welcome.jpeg";
import Banner from "../elements/Banner";
import useWindowDimensions from "../../useWindowDimensions";
import PageTitle from "../elements/PageTitle";

export default function PublicTreatment() {
  const { width } = useWindowDimensions();
  return (
    <React.Fragment>
      <PageTitle
        title="Kassenbehandlung"
        canonical={"kassenbehandlung"}
        description="Wir sind der Überzeugung, dass Sie sich als Patient*in oder Elternteil
        schon vorab über die möglichen Zusatzkosten einer kieferorthopädischen
        Behandlung informieren können sollten."
      />
      <div className="page-content services-page bg-white">
        <Banner title="Kassenbehandlung">
          {width < 520 ? (
            <>
              Kassen-
              <br />
              behandlung
            </>
          ) : (
            "Kassenbehandlung"
          )}
        </Banner>
        <section className="section-area section-sp1">
          <MainServices />
          <HowItWorks />
          <CashBenefits />
          <AdditionalServices />
          <StrictBudgeting />
        </section>
      </div>
    </React.Fragment>
  );
}

const MainServices = () => {
  const mainOptions = [
    {
      title: "Behandlungsdauer",
      subtitle: "Unsere Planung",
      text: (
        <React.Fragment>
          Meistens planen wir jede kieferorthopädische Kassen-Hauptbehandlung
          auf 16 Quartale, also 4 Jahre.
          <br />
          <br />
          Hierbei stehen uns grundsätzlich die ersten 12 Quartale, also 3 Jahre,
          als aktive kieferorthopädische Behandlungszeit zur Verfügung.
          <br />
          <br />
          Die darauf folgenden 4 Quartale, also ein Jahr, sind zur
          Stabilisierung des Ergebnisses, also zur Retention der
          kieferorthopädischen Behandlung vorgesehen.
          <br />
          <br />
          Bei Frühbehandlungen stehen uns 6 Behandlungsquartale, also 18 Monate,
          zur Verfügung. Eine Retentionsphase ist hier nicht vorgesehen.
          <br />
          <br />
          Sollte es im Einzelfall aufgrund besonderer Vorkommnisse zu einer
          längeren Therapie kommen, beantragen wir bei Ihrer Krankenkasse eine
          meist unkompliziert bewilligte Verlängerung der kieferorthopädischen
          Hauptbehandlung.
          <br />
          <br />
          In diesem Fall erhöhen sich die Behandlungskosten etwas. Bei
          Frühbehandlungen sind leider keine Verlängerungsanträge möglich.{" "}
        </React.Fragment>
      ),
      img: behandlungsdauer,
      borderColor: "#CD7F32",
    },
    {
      title: "Eigenanteil",
      subtitle: "Rechnungen sammeln",
      text: (
        <React.Fragment>
          Vom Betrag, den die gesetzliche Krankenkasse für die
          kieferorthopädische Behandlung übernimmt (meist rund 3000 €), müssen
          Sie 20 % der geplanten Kassenkosten als Eigenanteil übernehmen. Je
          weiterem Kind zahlen Sie nur 10 %.
          <br />
          <br />
          Der Eigenanteil wird Ihnen von uns aufgeteilt auf die geplante
          Behandlungsdauer der Kieferorthopädie quartalsweise in Rechnung
          gestellt.
          <br />
          <br />
          Der Eigenanteil soll seitens der Krankenkasse wie eine Kaution wirken
          und Sie dazu motivieren, die Behandlung komplett durchführen zu
          lassen.
          <br />
          <br />
          Bei erfolgreichem Abschluss der Kieferorthopädie bekommen Sie den
          Eigenanteil von der Krankenkasse erstattet.
          <br />
          <br />
          Bitte heben Sie daher unbedingt alle Rechnungen von uns auf.{" "}
        </React.Fragment>
      ),
      img: eigenanteil,
      borderColor: "silver",
    },
    {
      title: "Kassenleistungen",
      subtitle: "Schulnote 4",
      text: (
        <React.Fragment>
          Die Kassenrichtlinien wurden 2002, also vor über 20 Jahren,
          festgelegt. Wie man sich vorstellen kann, hat der medizinische
          Fortschritt zum Glück auch keinen Halt vor der Kieferorthopädie
          gemacht.
          <br />
          <br />
          Als Kassenpatient*in haben Sie zwar ein Recht auf eine Versorgung mit
          den Kassenleistungen, aber "die Leistungen müssen ausreichend,
          zweckmäßig und wirtschaftlich sein." [§ 12 SGB V
          Wirtschaftlichkeitsgebot]
          <br />
          <br />
          Diese Rechtsgrundlage lässt sehr schnell verstehen, dass das bei
          Weitem nicht die medizinisch bestmögliche Versorgung darstellt, die
          Sie für sich oder Ihr Kind wünschen sollten. Schließlich ist man im
          Idealfall nur ein Mal im Leben in kieferorthopädischer Behandlung.
          <br />
          <br />
          In der Schule hat man auch nicht das Ziel, "ausreichend", also eine 4,
          als Note zu erhalten. Warum sollte man das auch für die Gesundheit
          wollen?
        </React.Fragment>
      ),
      img: kassenleistungen,
      borderColor: "gold",
    },
  ];

  return (
    <div className="container">
      <h2 className="">Behandlung gesetzlich Versicherter</h2>
      <p>
        In der Kassen-Kieferorthopädie gruppiert man die Fehlstellungen in 5
        Schweregrade ("Kieferorthopädische Indikationsgruppen").
        <br />
        <br />
        Bei Kindern und Jugendlichen im späten Wechselgebiss wird die
        gesetzliche Krankenversicherung leistungspflichtig, sobald mindestens
        Schweregrad 3 vorliegt.
        <br />
        <br />
        Bei Kleinkindern mit Milchgebiss oder frühem Wechselgebiss fallen nur
        bestimmte Fehlstellungen für eine sogenannte Frühbehandlung in die
        Kassenversorgung. Manchmal muss man also noch ein wenig warten, bis mehr
        bleibende Zähne durchbrechen und die Behandlung begonnen werden darf.
        <br />
        <br />
        Bei den Schweregraden 1 und 2 liegt zwar eine medizinisch
        behandlungsbedürftige Fehlstellung vor, die Behandlung darf jedoch aus
        wirtschaftlichen Gründen nicht zu Lasten der Krankenkasse erfolgen.
        <br />
        <br />
        Erwachsene haben selten die Möglichkeit, in den Genuss einer
        Kassenbehandlung zu kommen. Ausschließlich in sehr schwerwiegenden
        Fällen, bei denen zusätzlich eine kieferchirurgische Maßnahme zum
        Umstellen der Kiefer notwendig ist, kann eine Kostenübernahme durch die
        gesetzliche Krankenversicherung erfolgen.
        <br />
        <br />
        Trotz der Leistungen der Krankenkasse muss man mit gewissen
        Eigenanteilen und zusätzlichen Privatleistungen rechnen, wenn man die
        bestmögliche Versorgung nach dem aktuellen Stand der Wissenschaft
        erhalten möchte.
        <br />
        <br />
        Um Ihnen größtmögliche Transparenz zu bieten, finden Sie hier die
        wichtigsten Informationen dazu:
      </p>
      <div className="row mt-50">
        {mainOptions.map((x, i) => (
          <div key={"option-" + i} className="col-lg-4 col-md-6 mb-30">
            <div className="feature-container feature-bx2 feature1">
              <div className="feature-box-2 mb-20">
                <img src={x.img} alt="img" />
              </div>
              <div className="icon-content">
                <h3 className="ttr-title">{x.title}</h3>
                <h6 className="ttr-subtitle">{x.subtitle}</h6>
                <p>{x.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const AdditionalServices = () => {
  const secondaryOptions = [
    {
      img: bronze,
      title: "Bronze\nHauptbehandlung",

      subtitle: "Schulnote 3",

      text: `
            Es ist nicht viel leistbar und möchten dennoch nicht auf die aus unserer Sicht wichtigsten Zusatzleistungen in der Kieferorthopädie verzichten?
            <br/>
            <br/>
            Dann ist unser Bronze-Paket Ihre Wahl.
            <br/>
            <br/>
            <p>
                <u>Inkludiert sind hier:</u>
            </p>
            <ol class="public-treatment__additional-services__list">
              <li>keine Außenspange</li>
              <li>digitaler Workflow</li>
              <li>metallfarbene, vorprogrammierte, superelastische, thermoaktive Nickel-Titan-Bögen</li>
              <li>metallfarbene, vorprogrammierte Mini-Brackets mit Gummi-Ligaturen</li>
            </ol>
          `,
      color: " #CD7F32",
    },
    {
      img: silver,
      title: "Silber\nHauptbehandlung",
      subtitle: "Schulnote 2",
      text: `
            Unser meistgewähltes Paket!
            <br/>
            <br/>
            <p>
            <u>
            Inkludiert sind hier alle Leistungen aus "Bronze", zusätzlich:
            </u>
            </p>
            <ol class="public-treatment__additional-services__list">
            <li>metallfarbene, selbstlegierende sowie vorprogrammierte Mini-Brackets</li>
              <li>Bracketumfeldversiegelung zur Verringerung des Kariesrisikos</li>
              <li>Funktions- und Strukturanalysen zur Sicherstellung der richtigen Kiefergelenks- und Muskelfunktionen</li>
              <li>festsitzende Retainer im Ober- und Unterkiefer</li>
            </ol>
          `,
      color: "silver",
    },
    {
      img: gold,
      title: "Gold\nHauptbehandlung",
      subtitle: "Schulnote 1",

      text: `
            Wir legen höchsten Wert auf erstklassige Behandlungsqualität. Sie auch?
            <br/>
            <br/>
            Mit unseren Premium-Leistungen sichern Sie sich bzw. Ihrem Kind die bestmögliche und ästhetischste Versorgung nach dem aktuellen Stand der Kieferorthopädie.
            <br/>
            <br/>
            <p>
            <u>Inkludiert sind hier alle Leistungen aus "Bronze" und "Silber", anders sind jedoch Brackets und Bögen:</u>
            </p>
            <ol class="public-treatment__additional-services__list">
              <li>ästhetisch helle, vorprogrammierte, superelastische, thermoaktive Nickel-Titan-Bögen</li>
              <li>ästhetisch transparente, selbstlegierende sowie vorprogrammierte Mini-Brackets</li>
            </ol>
          `,
      color: "gold",
    },
    {
      img: welcome,
      title: "Welcome\nFrühbehandlung",
      subtitle: "ohne Benotung wie in der Grundschule :)",

      text: `
            Auch bei kleinen Kindern, die eine kurze kieferorthopädische Frühbehandlung erhalten, haben wir das richtige Angebot sinnvoller Zusatzleistungen.
            <br/>
            <br/>
            <p>
            <u>
            Inkludiert sind hier:
            </u>
            </p>
            <ol class="public-treatment__additional-services__list">
              <li>digitaler Workflow</li>
              <li>Funktions- und Strukturanalysen zur Sicherstellung der richtigen Kiefergelenks- und Muskelfunktionen</li>
            </ol>
          `,
      color: "#053e51",
    },
  ];

  return (
    <div className="public-treatment__additional-services container mt-40">
      <h2>Unsere Mehrleistungen</h2>
      <p>
        Wir sind der Überzeugung, dass Sie sich als Patient*in oder Elternteil
        schon vorab über die möglichen Zusatzkosten einer kieferorthopädischen
        Behandlung informieren können sollten.
        <br />
        <br />
        Daher haben wir uns für eine transparente Aufstellung der möglichen,
        medizinisch sinnvollen Zusatzleistungen in drei Kategorien entschieden.
        <br />
        <br />
        <u>
          Diese liegen zwischen rund 2.000 und 6.000 € bei Hauptbehandlungen
          sowie bis zu 500 € bei Frühbehandlungen.
        </u>
        <br />
        <br />
        Bitte beachten Sie dabei, dass selbstverständlich dennoch jeder Fall
        gemäß Gebührenordnung der Zahnärzte (GOZ) individuell berechnet wird und
        die Kosten (leicht) abweichen können.
      </p>
      <div className="row mt-50">
        {secondaryOptions.map((x, i) => (
          <div key={"option-" + i} className="col-lg-3 col-md-6 mb-30">
            <div
              style={{
                border: `3px solid ${x.color}`,
                padding: "14px",
              }}
              className="feature-container feature-bx2 feature1"
            >
              <div className="feature-box-2 mb-20">
                <img src={x.img} alt="img" />
              </div>
              <div className="icon-content">
                <h5 className="ttr-title">{x.title}</h5>
                <br />
                <h5 className="ttr-subtitle">{x.subtitle}</h5>
                <br />
                <div dangerouslySetInnerHTML={{ __html: x.text }}></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const HowItWorks = () => {
  const navigate = useNavigate();

  return (
    <div className="public-treatment__how-it-works container">
      <div className="public-treatment__how-it-works__content">
        <div className="public-treatment__how-it-works__content__video-box">
          <iframe
            width="375"
            // height="315"
            src="https://www.youtube.com/embed/9g8bTWZZAPY?si=YQQqRhe7JWgdhztY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <h3>Exzellenz mit einer Invisalign-Behandlung</h3>
          <p>
            Wir sind eine der wenigen Invisalign-Anwender in Braunschweig, die
            mit den durchsichtigen Schienen jede (!) Fehlstellung behandeln
            können.
            <br />
            <br />
            Entscheiden auch Sie sich für die modernste und aus unserer Sicht
            beste Art Zahnspange für die Kieferorthopädie Ihres Kindes.
            <br />
            <br />
            <strong>
              Verzichten Sie bei Ihrem Kind komplett auf die Kassenbeteiligung
              und wir reduzieren unser Honorar einer rein privaten
              Invisalign-Behandlung um rund 1.000 €.
            </strong>
            <br />
            <br />
            Bitte beachten Sie dabei, dass selbstverständlich dennoch jeder Fall
            gemäß Gebührenordnung der Zahnärzte (GOZ) individuell berechnet wird
            und die Kosten (leicht) abweichen können.
            <br />
            <br /> Sie möchten trotzdem die kieferorthopädische Behandlung mit
            einer traditionellen Zahnspange und Brackets? Dann lesen Sie bitte
            hierfür weiter.
          </p>
        </div>
      </div>
      <button
        onClick={() => {
          navigate("/privatbehandlung");
        }}
        className="btn btn-secondary"
      >
        Hier geht es zur Invisalign-Behandlung
      </button>
    </div>
  );
};

const CashBenefits = () => {
  const secondaryOptions = [
    {
      title: "Außenspange",
      text: (
        <React.Fragment>
          Die von außen zu tragenden Zahnspangen. Funktionieren zwar super,
          beeinträchtigen die Ästhetik des Gesichts jedoch leider massiv.
          <br />
          <br />
          In den meisten Fällen gibt es moderne, innenliegende Zahnspangen, die
          die Funktion der Außenspange erfüllen.
          <br />
          <br />
          Kinder, die eine Außenspange tragen mussten, berichten leider immer
          wieder von Hänseleien.
          <br />
          <br />
          Lassen Sie uns das unbedingt vermeiden, insofern eine für die
          kieferorthopädische Behandlung notwendig ist.
          <br />
          <br />
          Denn die Mundgesundheit ist uns zwar wichtig, die psychische aber
          auch.{" "}
        </React.Fragment>
      ),
      img: ausenspange,
    },
    {
      title: "Brackets",
      text: (
        <React.Fragment>
          Die riesigen Kassen-Brackets lassen sich leider sehr schwierig
          reinigen, schließlich ist super viel von der Zahnfläche bedeckt.
          <br />
          <br />
          Lassen Sie uns daher Mini-Brackets nutzen und den Zahnschmelz um die
          Brackets herum versiegeln. So reduzieren Sie massiv das Kariesrisiko
          während der Behandlung.
          <br />
          <br />
          Selbstlegierende Brackets haben zudem den großen Vorteil, dass keine
          Gummis zum Halten des Bogens genommen werden.
          <br />
          <br />
          Gummis verfärben nicht nur leicht, sondern bremsen auch den Bogen. Das
          ist so, als würde man mit angezogener Handbremse Auto fahren.
          <br />
          <br />
          Bei selbstlegierenden Brackets werden die Bewegungen also besser,
          angenehmer und schneller umgesetzt.{" "}
        </React.Fragment>
      ),
      img: brackets,
    },
    {
      title: "Bögen",
      text: (
        <React.Fragment>
          Die von der gesetzlichen Krankenkasse bezahlten Edelstahlbögen sind,
          anders als die von uns empfohlenen superelastischen, thermoaktiven
          Nickel-Titan-Bögen, sehr starr und daher deutlich schmerzhafter.
          <br />
          <br />
          Außerdem "erinnern" sie sich nicht so gut an die ursprüngliche Form
          wie die Nickel-Titan-Bögen, sodass es mit den Kassen-Bögen meist auch
          noch länger braucht und wir zudem öfter diverse händische Biegungen
          mit häufigeren Sitzungen brauchen.
          <br />
          <br />
          Vermeiden Sie also die Einnahme von Schmerzmitteln und das Fehlen in
          der Schule, indem Sie sich für den Goldstandard bei den Bögen
          entscheiden.{" "}
        </React.Fragment>
      ),
      img: bogen,
    },
    {
      title: "Digitaler Workflow",
      text: (
        <React.Fragment>
          Analoge Herstellungs- und Diagnostikmethoden sind veraltet.
          Herkömmliche Abdrücke können sich verziehen, auch bei der Herstellung
          haben Zahntechniker*innen häufiger mit Problemen zu kämpfen als bei
          den hochpräzisen, digitalen Abläufen.
          <br />
          <br />
          Mit intraoralen Scans stellen wir nicht nur die höchstmögliche
          Qualität, sondern auch den besten Tragekomfort sicher.
          <br />
          <br />
          Vermeiden Sie Passungsprobleme und ersparen Sie Ihrem Kind so einen
          nervigen Sitz der Zahnspange und häufigere Sitzungen für Anpassungen.`
        </React.Fragment>
      ),
      img: workflow,
    },
    {
      title: "Funktionsdiagnostik",
      text: (
        <React.Fragment>
          Die Kiefergelenke sind einzigartig im Körper. Nur sie arbeiten
          dreidimensional in alle Richtungen. Und unsere Kaumuskulatur ist die
          stärkste im gesamten Körper.
          <br />
          <br />
          Aus unserer Sicht gehört deswegen zu einer guten Kieferorthopädie eine
          ausgiebige Funktions- und Strukturanalyse dazu. So stellen wir sicher,
          dass die Kiefergelenke und Kaumuskulatur am Ende so arbeiten wie sie
          sollen. Schließlich muss man Zähne, Kiefer, Kiefergelenke und die
          gesamte Kaumuskulatur als unser Kauorgan im Gesamten sehen.
          <br />
          <br />
          Vermeiden Sie Folgeprobleme, die ein Leben lang zu Beschwerden führen
          können.{" "}
        </React.Fragment>
      ),
      img: diagnostik,
    },
    {
      title: "Retainer",
      text: (
        <React.Fragment>
          Am Ende der Behandlung erhalten Sie auf Kassenkosten ein Set einfacher
          Schienen zum Festhalten der Zähne in ihrer Position. Diese halten in
          der Regel rund 2 Jahre.
          <br />
          <br />
          Heutzutage ist aber eine lebenslange Retention absoluter Goldstandard.
          <br />
          <br />
          Daher empfehlen wir, zusätzlich am Ende dauerhafte Retainer auf die
          Innenseite der Frontzähne zu kleben.
          <br />
          <br />
          So hat man doppelten Schutz und muss nicht direkt um einen Rückfall
          Sorgen haben, wenn man auch einmal die herausnehmbaren Schienen
          vergessen sollte.
          <br />
          <br />
          Vermeiden Sie mit der bestmöglichen Retention so teure
          Folgebehandlungen.
        </React.Fragment>
      ),
      img: retainer,
    },
  ];
  return (
    <div className="public-treatment__cash-benefits container mt-50">
      <h3 className="secondary-text">
        Verlassen Sie sich nicht auf die reine Kassenleistung!
        <br />
        <br />
        Was gefällt uns daran nicht?
      </h3>

      <div className="row mt-50">
        {secondaryOptions.map((x, i) => (
          <div key={"option-" + i} className="col-lg-4 col-md-6 mb-30">
            <div className="feature-container feature-bx2 feature1">
              <div className="feature-box-2 mb-20">
                <img src={x.img} alt="img" />
              </div>
              <div className="icon-content">
                <h3 className="ttr-title">{x.title}</h3>
                <p>{x.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const StrictBudgeting = () => {
  return (
    <div
      className="
    public-treatment__strict-budget 
    container mt-50"
    >
      <h3>Strikte Budgetierung</h3>
      <p>
        Für viele der Kassenleistungen erhalten wir keine Vergütung. Dies liegt
        an einem striktem Budget seit 2023.
      </p>

      <p className="public-treatment__strict-budget--bold-text ">
        Die reinen Kassenmethoden sind nicht nur aus medizinischer Sicht
        veraltet, sondern auch deutlich zeitaufwändiger. Die Budgetierung zwingt
        uns daher nun, ab sofort Behandlungen ohne private Zusatzleistungen
        hauptsächlich am weniger ausgelasteten Vormittag anzubieten. Termine am
        Nachmittag sind in der Regel nicht möglich.
      </p>

      <p>
        Mit dem Finanzstabilisierungsgesetz wurden nämlich die Mittel für
        zahnärztliche Leistungen gekürzt. Und dies, obwohl durch die
        präventionsorientierte Ausrichtung in der Zahnmedizin der Anteil an den
        Gesamtausgaben der gesetzlichen Krankenversicherung für die
        vertragszahnärztliche Versorgung seit Jahren kontinuierlich gesunken
        ist.
      </p>
      <p>
        Wir ermutigen alle Patient*innen, sich gegen das Sparen in der
        Gesundheitspolitik zu wehren! Denn insgesamt kann es für begrenzte
        Mittel keine unbegrenzten Leistungen geben. Es drohen negative Folgen
        für die Mund- und Allgemeingesundheit. Die Verantwortung hierfür liegt
        bei der Politik.
      </p>
      <p>
        Mit der Kampagne „Zähne zeigen“ will die Kassenzahnärztliche
        Bundesvereinigung gemeinsam mit den Kassenzahnärztlichen Vereinigungen
        der Länder und im Schulterschluss mit der Bundeszahnärztekammer, den
        Landeszahnärztekammern und den Verbänden die Patient*innen über die
        fatalen Konsequenzen der Budgetierung der Mittel für zahnärztliche
        Leistungen aufklären.
      </p>
      <p>
        Die Kampagne soll dazu beitragen, dass die Budgetierung der Mittel für
        zahnärztliche Leistungen im Sinne Ihrer Mundgesundheit wieder aufgehoben
        wird.
      </p>
      <button
        onClick={() => {
          window.open(
            "https://www.zaehnezeigen.info/jetzt-mitmachen/",
            "_blank"
          );
        }}
        className="btn btn-primary mt-20"
      >
        Jetzt Zähne zeigen! Wehren Sie sich gegen die Budgetierung im
        Gesundheitssystem!
      </button>
    </div>
  );
};
