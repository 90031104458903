import React from "react";
import { Helmet } from "react-helmet-async";

const PageTitle = ({ title, description, canonical }) => {
  return (
    <Helmet prioritizeSeoTags>
      <title>{title} - OpenSoft</title>
      <meta name="description" content={description} />
      <link
        rel="canonical"
        href={`https://www.fliegender-zahn.de/${canonical}`}
      />
    </Helmet>
  );
};

export default PageTitle;
