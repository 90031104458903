import React, { useContext } from "react";
import { Link } from "react-router-dom";

import bnrImg1 from "../../images/banner/img1.jpg";
import trangleOrange from "../../images/shap/triangle-orange.png";
import squareDotsOrange from "../../images/shap/square-dots-orange.png";
import lineCircleBlue from "../../images/shap/line-circle-blue.png";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/circle-dots.png";
import teamMember1 from "../../images/team/member1.jpg";
import teamMember2 from "../../images/team/member2.jpg";
import teamMember3 from "../../images/team/member3.jpg";
import teamMember4 from "../../images/team/member4.jpg";
import teamMember5 from "../../images/team/member5.jpg";
import teamMember6 from "../../images/team/member6.jpg";
import teamInfo from "../../images/team/team-info.png";
import teamGroupImage from "../../images/team/team-group-image.jpeg";
import team2 from "../../images/team/team-2.png";
import milanImage from "../../images/team/milan-keyhani.jpeg";
import milan2 from "../../images/team/milan-2.jpg";
import christine from "../../images/team/christine.jpeg";
import clinic from "../../images/home/clinic.jpeg";
import clinic2 from "../../images/home/clinic-2.jpeg";
import clinic3 from "../../images/home/clinic-3.jpeg";

import image1 from "../../images/gesunde.jpg";
import { Context } from "../../context";
import { Carousel } from "./Carousel/Carousel";
import BookingButton from "./BookingButton";

export default function PracticeSection() {
  const { selectedFlow } = useContext(Context);

  const isOrtho = selectedFlow === "ortho";

  const heading = isOrtho ? "Liebe Patient*innen und Eltern," : "Liebe Eltern,";

  const text = isOrtho ? (
    <p>
      <br />
      von Online-Terminbuchung über digitales Röntgen, digitale Abformungen
      mithilfe von intraoralen Scans, digitale Behandlungsplanung bis über das
      3D-Drucken der Apparaturen.
      <br />
      <br /> Bei uns nutzen wir modernste technische Verfahren, um Ihnen die
      aktuellste und bestmögliche kieferorthopädische Behandlung zu ermöglichen.
      <br />
      <br />
      Ob jung oder alt, ob gesetzlich oder privat versichert: Bei uns fühlt sich
      jede*r wohl.
      <br />
      `Wir freuen uns auf Sie!
    </p>
  ) : (
    <p>
      <br />
      in unserer Praxis für Kieferorthopädie sowie Kinder- und
      Jugendzahnheilkunde steht eine moderne, kindgerechte Behandlung im
      Mittelpunkt, die auf dem neuesten Wissensstand basiert. Wir legen großen
      Wert auf eine herzliche Atmosphäre, damit sich Ihr Kind bei uns wohlfühlt.
      <br />
      <br />
      Unser Ziel ist es, das Vertrauen Ihres Kindes zu gewinnen und
      sicherzustellen, dass es gerne zu uns kommt. Es liegt uns am Herzen, dass
      Ihr Kind sich bei uns gut aufgehoben fühlt.
    </p>
  );

  return (
    <div className="home-page">
      <div className="home-page__heading-text container">
        <h3>{heading}</h3>
        {text}
      </div>
      <section className="section-area section-sp1 team-wraper">
        {/* <BestTeam /> */}

        <OurPractice isOrtho={isOrtho} />
        {selectedFlow === "pediatric" && <YourFirstVisit isOrtho={isOrtho} />}

        <div className="home-page__feature container mt-50">
          <img src={christine} />
          <div>
            <h2 className="home-page__feature__heading">
              Christine Zarro, M. Sc.
            </h2>
            {!isOrtho && (
              <h4 className="text-secondary">
                Zahnärztin
                <br />
                Schwerpunkt Kinder- und Jugendzahnheilkunde
              </h4>
            )}
            <h4
              style={{
                paddingTop: "8px",
              }}
              className="home-page__feature__subheading"
            >
              {isOrtho && (
                <>
                  Zahnärztin
                  <br />
                </>
              )}
              Master of Science Kieferorthopädie
              <br />
              <br />
            </h4>
            <p>
              Mein Name ist Christine Zarro. Schon als Kind wollte ich
              Zahnärztin werden. Nach meinem Studium an der Medizinischen
              Hochschule Hannover habe ich das postgraduale Masterstudium der
              Kieferorthopädie absolviert. Als Mutter von zwei wunderbaren
              Kindern weiß ich, wie wichtig ein strahlendes Lächeln für Kinder
              ist. Ich freue mich darauf, Sie und Ihre Kinder bei uns begrüßen
              zu dürfen.
            </p>
          </div>
        </div>

        <div className="home-page__feature home-page__feature--reverse container">
          <img src={milan2} />
          <div>
            <h2 className="home-page__feature__heading">
              d-r Milan Keyhani,
              <br />
              M. Sc., B. Sc., M. Sc.
            </h2>
            {!isOrtho && (
              <h4 className="text-secondary">
                Zahnarzt
                <br />
                Schwerpunkt Kinder- und Jugendzahnheilkunde
              </h4>
            )}
            <h4
              className="home-page__feature__subheading "
              style={{
                paddingTop: "8px",
              }}
            >
              {isOrtho && (
                <>
                  Zahnarzt
                  <br />
                </>
              )}
              Master of Science Kieferorthopädie
              <br />
            </h4>
            <p>
              Ich bin Milan Keyhani. Auch ich wollte schon als Kind in die
              Zahnmedizin gehen, fand die Fliegerei aber ebenfalls immer
              interessant. Daher freue ich mich umso mehr, Sie und Ihr Kind bei
              uns in der Praxis für Kieferorthopädie sowie Kinder- und
              Jugendzahnheilkunde "Fliegender Zahn" begrüßen zu dürfen. Meine
              Schwerpunkte liegen in der Kieferorthopädie bei
              Invisalign-Behandlungen und in der allgemeinen Zahnheilkunde bei
              Wurzelbehandlungen. Wir freuen uns auf Sie in unserer
              einzigartigen Praxis!
            </p>
          </div>
        </div>

        {/* <div className="container">
          <div className="row">
            {teamMembers.map((teamMember, index) => (
              <div key={index} className="col-lg-4 col-sm-6">
                <div className="team-member mb-30">
                  <div className="team-media">
                    <img src={teamMember.thumb} alt="" />
                  </div>
                  <div className="team-info">
                    <div className="team-info-comntent">
                      <h4 className="title">{teamMember.title}</h4>
                      <span className="text-secondary">
                        {teamMember.subtitle}
                      </span>
                    </div>
                    <ul className="social-media mt-3">
                      {teamMember.socialMedia.map((social, index) => (
                        <li key={index}>
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={social.link}
                          >
                            <i
                              className={`fab fa-${social.platform.toLowerCase()}`}
                            />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}

        <img className="pt-img1 animate1" src={trangleOrange} alt="" />

        <img className="pt-img2 animate2" src={squareDotsOrange} alt="" />

        <img className="pt-img3 animate-rotate" src={lineCircleBlue} alt="" />

        <img className="pt-img4 animate-wave" src={waveBlue} alt="" />

        <img className="pt-img5 animate-wave" src={plusBlue} alt="" />
      </section>
    </div>
  );
}

const BestTeam = () => {
  return (
    <div className="home-page__best-team container">
      <img
        className="home-page__best-team__image"
        src={teamMember2}
        alt="group-team-photo"
      />
      <div>
        <h4 className="home-page__best-team__heading">
          Bestes Praxisteam 2022!
        </h4>
        <p>
          Erst bester Ausbildungsbetrieb 2021 in Braunschweig und nun auch
          bestes Praxisteam 2022 in ganz Deutschland.
          <br />
          Vielen Dank für diese beiden unfassbar tollen Auszeichnungen!
        </p>
      </div>
    </div>
  );
};

const OurPractice = ({ isOrtho }) => {
  const text = isOrtho ? (
    <p className="text-with-image__text text-justify">
      Das wichtigste Gut für uns ist die Gesundheit. Wir werden nicht nur
      höchsten ästhetischen Ansprüchen gerecht, sondern achten auch auf die
      Wiederherstellung der möglichst idealen Kaufunktion.
      <br />
      <br />
      CMD (Cranio-Mandibuläre-Dysfunktion) bzw. Bruxismus und andere
      funktionelle Störungen können zum Beispiel Zahn- und Kieferfehlstellungen
      als Ursache haben. Um diese Beschwerdebilder zu vermeiden, ist es wichtig,
      bei Fehlstellungen so früh wie möglich kieferorthopädisch einzugreifen.
      <br />
      <br />
      Die Erstuntersuchung eines jeden Kindes in der kieferorthopädischen Praxis
      sollte daher spätestens mit dem frühen Wechselgebiss erfolgen, also wenn
      alle bleibenden, ersten großen Backenzähne vorhanden sind.
      <br />
      <br />
      Doch auch bei Erwachsenen lassen sich die meisten funktionellen Probleme
      durch eine Zahnbegradigung behandeln. Es gibt in der Kieferorthopädie kein
      Höchstalter und selbst bei Vorerkrankungen wie Parodontitis lassen sich
      die Behandlungen bei guter Planung sehr gut durchführen.
    </p>
  ) : (
    <p className="text-with-image__text text-justify">
      Wir setzen auf langfristige Zahngesundheit.
      <br />
      <br />
      Gesunde Milchzähne bilden die Grundlage für dauerhaft gesunde Zähne. Durch
      regelmäßige Besuche in unserer Praxis können wir frühzeitig erforderliche
      Maßnahmen erkennen und umgehend durchführen, ohne Ihr Kind zu überfordern.
      <br />
      <br />
      Mit kleinen Eingriffen erzielen wir eine große, nachhaltige Wirkung und
      schützen die Zähne Ihres Kindes präventiv vor Erkrankungen.
      <br />
      <br />
      Qualität steht bei uns an erster Stelle.
      <br />
      <br />
      Wir wissen, dass es um die Gesundheit Ihres Kindes geht. Deshalb legen wir
      großen Wert auf die fachliche Qualifikation aller Mitarbeiterinnen und
      Mitarbeiter, sowie unsere eigene. Wir verwenden ausschließlich hochwertige
      Materialien und modernste Technologie.
      <br />
      <br />
      Höchste Hygienestandards sind für uns selbstverständlich, ebenso wie eine
      umfassende, professionelle Betreuung und Organisation.
    </p>
  );
  return (
    <div className="container">
      <h2 className="home-page__group-info__heading">
        {isOrtho ? "Gesunde und gerade Zähne:" : ""}
        {isOrtho ? <br /> : ""}
        Ihre {isOrtho
          ? "Kieferorthopädie"
          : "Kinder- und Jugendzahnheilkunde"}{" "}
        in Braunschweig
      </h2>
      <div
        className={`${
          isOrtho ? "text-with-image text-with-image--reverse" : ""
        }`}
      >
        {isOrtho && (
          <img className="home-page__group-info__image" src={image1} />
        )}
        <div>
          {text}
          <BookingButton
            classes="btn-primary mx-auto mb-50 mt-30"
            text="Buchen Sie hier Ihren Termin online!"
          />
        </div>
      </div>

      {/* TODO: Make gallery */}
      <Carousel>
        <img src={clinic2} />
        <img src={clinic} />
        <img src={clinic3} />
      </Carousel>
    </div>
  );
};

const YourFirstVisit = ({ isOrtho }) => {
  return (
    <div className="container home-page__your-first-visit mt-50">
      <h2>Der erste Besuch</h2>

      <div className="text-with-image text-with-image--reverse">
        <img
          className="home-page__your-first-visit__image"
          src={image1}
          style={{
            width: "100%",
            maxHeight: "650px",
            objectFit: "cover",
          }}
        />

        <p className="text-with-image__text text-justify">
          Ein Ratgeber für Eltern:
          <br />
          <br />
          Bereiten Sie Ihr Kind zu Hause spielerisch auf den Zahnarztbesuch vor.
          Zeigen Sie ihm, wie wichtig der Mund und die Zähne sind, und wecken
          Sie seine Neugierde auf den Besuch beim Zahnarzt.
          <br />
          <br />
          Gestalten Sie den Tag des Besuchs stressfrei und sorgen Sie dafür,
          dass Sie und Ihr Kind ausgeruht sind.
          <br />
          <br />
          Vermeiden Sie Aussagen wie "Es tut bestimmt nicht weh", und erklären
          Sie stattdessen, dass viele Kinder sagen, es würde kitzeln.
          <br />
          <br />
          Seien Sie geduldig und lassen Sie Ihrem Kind Zeit, sich an die
          Behandlungssituation zu gewöhnen.
          <br />
          <br />
          Verzichten Sie auf Versprechungen von Belohnungen, da dies Druck
          aufbauen kann.
          <br />
          <br />
          Ihre Anwesenheit im Behandlungszimmer ist willkommen, aber lassen Sie
          das zahnärztliche Team die Führung übernehmen. Eine gute
          Zusammenarbeit zwischen Eltern und Zahnarzt ist entscheidend.
          <br />
          <br />
          Manchmal ist es besser, wenn Kinder allein ins Behandlungszimmer
          gehen, um eine bessere Kontaktaufnahme mit dem Team zu ermöglichen.
          <br />
          <br />
          Kinder ab ca. 4 Jahren können den Zahnarztbesuch allein meistern und
          daran wachsen. Bitte haben Sie Verständnis dafür, dass wir aus
          kapazitären Gründen grundsätzlich keine Kinder unter 4 Jahren
          behandeln.
        </p>
      </div>
    </div>
  );
};

const teamMembers = [
  {
    thumb: teamMember1,
    title: "Dr. Addition Smith",
    subtitle: "Dentist",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember2,
    title: "Dr. Mahfuz Riad",
    subtitle: "Chiropractor",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember3,
    title: "Dr. David Benjamin",
    subtitle: "Cardiologist",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember4,
    title: "Dr. Addition Smith",
    subtitle: "Dentist",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember5,
    title: "Dr. Mahfuz Riad",
    subtitle: "Chiropractor",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
  {
    thumb: teamMember6,
    title: "Dr. David Benjamin",
    subtitle: "Cardiologist",
    socialMedia: [
      { platform: "Twitter", link: "https://twitter.com/" },
      { platform: "LinkedIn", link: "https://www.linkedin.com/" },
      { platform: "Instagram", link: "https://www.instagram.com/" },
    ],
  },
];
